
import './App.css';
import styles from "./style";
import Navbar from './Component/Navbar';
import Hero from './Component/Hero';
import Whatdo from './Component/whatdo';
import { Data } from './Constant'
import Footer from './Component/Footer';
import { BrowserRouter as Router, } from 'react-router-dom'

function App() {
  return (
    <>
    <Router>
      <div className='bg-primary w-full overflow-hidden font-poppins '>
        <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={` top-0 sticky ${styles.boxWidth}`}>
            <Navbar />
          </div>
        </div>

        <div className={`bg-primary md:h-screen relative ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <Hero />
          </div>
        </div>

        <div className={`bg-[#f9f8f3] ${styles.paddingX} ${styles.flexCenter} ${styles.paddingY}`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className='text-[#ffb15c] text-[25px] text-center font-bold'>What We Do</h1>
            <div className='grid md:grid-cols-3 w-full   px-2 py-8  gap-y-8 justify-center items-center md:w-[80%] md:mx-auto '>

              <Whatdo items={Data} />
            </div>

          </div>
        </div>
               <div className={`bg-neutral-100 ${styles.paddingX} ${styles.paddingY} `}>
                      <Footer/>
               </div>
      </div>
      </Router>
    </>
  );
}

export default App;
