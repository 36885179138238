import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
export default function Whatdo({items}) {
  return (
    <>
    {items && items.map((c,index)=>(
    <motion.div  initial={{opacity:0 ,scale:0 ,x:100}} whileInView={{opacity:1,scale:1,x:0}} transition={{duration:1}} //viewport={{once:true}}
     className='md:w-[350px] md:h-[250px] px-5 md:px-0  rounded-[5px] border-2 border-gray-50 drop-shadow-sm hover:cursor-pointer relative z-0' key={index}>
         <img src={c.image} alt="course" className='h-[200px] w-full'                  
         />
         <Link to={c.path} target={c.target}>
         <h1 className='font-poppins font-medium text-[18px] mt-3 px-1 capitalize  text-center text-[#02457a]'>{c.title}</h1> 
         </Link> 
         {/* <div className='bg-neutral-50 h-[166px] flex flex-col  items-center justify-end'>
            <button className="font-Roboto py-1 px-5 bg-[#2a3a8d] absolute bottom-4 text-white rounded-[5px]  text-[15px]"><Link to={`/coursedetail/${c.id}`}>See Details</Link></button> 
         </div>       */}
    </motion.div>
    ))}

</>
  )
  
}
