import React from 'react'
import { motion } from 'framer-motion'
import hero from '../asset/hero.jpg'
import { Link } from 'react-router-dom'
import Design from './Design'
// import { useNavigate } from 'react-router-dom'


const container = {
  hidden: { opacity: 0, },
  show: {
    opacity: 1,

    transition: {

      staggerChildren: 1
    }
  }
}

const item = {
  hidden: {
    opacity: 0, y: -100,
  },
  show: { opacity: 1, y: 0, transition: { duration: 1 } }
}

const item1 = {
  hidden: {
    opacity: 0, y: 100,
  },
  show: { opacity: 1, y: 0, transition: { duration: 1 } }
}
export default function Hero() {
  // const navigate=useNavigate()
  return (
    <div className='w-full md:h-screen h-full border-red-100 border-0 relative overflow-hidden bg-center bg-no-repeat ' style={{ backgroundImage: `url("")` }}
    >
      <section className='md:w-[80%] w-full md:h-[600px] h-[300px] md:mx-auto flex md:flex-row flex-col items-center md:gap-x-5 border-1 justify-center mt-0 md:py-5 px-5'>
        <motion.div initial="hidden" animate="show" variants={container}

          className='md:w-3/4 w-full font-Roboto bg-transparent '>
          <motion.h1 variants={item}
            className='md:text-6xl tracking-wide  text-2xl font-bold text-[#ffb15c] font-poppins'>Start A Career </motion.h1>
          <motion.p variants={item1} className='text-[#97cadb] text-sm md:w-4/5 w-full mt-2'>Technology is Bringing A Massive
            Wave of Evolution on Learning Things
            in Different Ways</motion.p>
          <motion.div className='flex gap-x-4 mt-5' variants={item1}>
            <button className='md:px-7 md:py-2 px-3 py-2 border-solid border-2 border-[#469b72] rounded-md text-white'>Get started</button>
            {/* <button className='md:px-7 md:py-2  px-3  py-2 rounded-md bg-indigo-900 text-white' onClick={()=>navigate("/courses")}>View Courses</button> */}
          </motion.div>
        </motion.div>

        <motion.div className='hidden md:flex md:w-1/2 w-full justify-end'

          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            damping: 10
          }}
        >

          {/* <img src={""} alt="heroimage" className='md:w-[900px]'/> */}
        </motion.div>

      </section>


      <div>

      </div>

      <div className="w-full h-screen absolute top-0 left-0">
        <Design />
      </div>
    </div>
  )
}
