import dsci from '../asset/data2.jpg'
import iot from '../asset/iot2.jpg'
import research from '../asset/research.jpg'
import software from '../asset/software.jpg'
import edu from '../asset/edu.jpg'
import community from '../asset/community.jpg'
export const navLinks = [
    {
      id: "home",
      title: "Home",
    },
    {
      id: "features",
      title: "Service",
    },
    {
      id: "product",
      title: "Product",
    },
    {
      id: "clients",
      title: "Clients",
    },
  ];



  export const Data=[
    // {
    //     id:"ds",
    //     title:"Data Science",
    //     image:dsci
        
        
    // },
    {
        id:"",
        title:"Software Developemnt",
        image:software,
        path:"#software"
      
    },
    // {
    //     id:"",
    //     title:"Internet of THings",
    //     image:iot,
       
    // },
    {
        id:"",
        title:"Research",
        image:research,
        path:"https://makeresearcheasy.com/",
        //category:"datascience"
        target:"_blank"
    },
    {
        title:"Education",
        image:edu,
        path:"#edu"

    },
    // {
    //     title:"Community",
    //     image:community
    // }
]