import React from 'react'
import {IoLocation} from 'react-icons/io5'
import {FaPhone} from 'react-icons/fa'

export default function Footer() {
  return (
    <div className='flex  flex-col md:flex-row justify-between  '>
           <h1 className='text-[#f8aa56] font-bold text-[1.4rem]'>InfintAI</h1>
           <div className='flex flex-col gap-y-2 font-normal font-poppins'>
                   <h2>Contact Us</h2>
                  
                   <span className='flex items-center gap-x-2  '>
                    <i className='bg-[#97cadb] p-2 rounded-[50%]'> <FaPhone size={12}/></i>+91-9894022944</span>
                  <span className='flex items-center gap-x-2'>
                    <i className='bg-[#97cadb] p-2 rounded-[50%]'> <IoLocation size={12}/></i>Trichy</span>
                    
                   
           </div>
    </div>
  )
}
